import React from 'react'
import PropTypes from 'prop-types'

import { Location } from '@reach/router'
import { LocaleContext } from '../Layout'
import { Row, Col } from '../UI/Grid'
import logo from '../../../content/images/logo-simple.svg'

import * as S from './styled'

const MenuDesktop = ({ isPt, locale }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <Location>
      {({ location }) => {
        const pathNameWithLocale = isPt ? `/en${location.pathname}` : location.pathname.replace('/en', '')

        return (
          <S.NavGrid>
            <S.InnerWrapper>
              <S.Row>
                <Col>
                  <S.Logo to={isPt ? '/' : '/en'}>
                    <img src={logo} alt="Bengalesisnika" />
                  </S.Logo>
                </Col>

                <S.Col>
                  <Row>
                    <S.Col>
                      <S.Row>
                        <S.LinkMenu
                          activeClassName="NavLink__currentPath"
                          to={isPt ? `/${i18n.link1}` : `/en/${i18n.link1}`}
                        >
                          {i18n.linkText1}
                        </S.LinkMenu>
                        <S.LinkMenu
                          activeClassName="NavLink__currentPath"
                          to={isPt ? `/${i18n.link2}` : `/en/${i18n.link2}`}
                        >
                          {i18n.linkText2}
                        </S.LinkMenu>
                        <S.ListItem>
                          <S.DropdownLink>{i18n.dropdown}</S.DropdownLink>
                          <S.Dropdown>
                            <S.Square />
                            <S.LinkMenu
                              activeClassName="NavLink__currentPath"
                              to={isPt ? `/${i18n.droplink1}` : `/en/${i18n.droplink1}`}
                            >
                              {i18n.droplinkText1}
                            </S.LinkMenu>
                            <S.LinkMenu
                              activeClassName="NavLink__currentPath"
                              to={isPt ? `/${i18n.droplink2}` : `/en/${i18n.droplink2}`}
                            >
                              {i18n.droplinkText2}
                            </S.LinkMenu>
                            <S.LinkMenu
                              activeClassName="NavLink__currentPath"
                              to={isPt ? `/${i18n.droplink3}` : `/en/${i18n.droplink3}`}
                            >
                              {i18n.droplinkText3}
                            </S.LinkMenu>
                          </S.Dropdown>
                        </S.ListItem>
                        <S.LocaleSwitcher data-name="locale-switcher">
                          {locale !== 'pt-pt' ? (
                            <S.MenuItem>
                              <S.LinkMenu hrefLang="pt-pt" to={pathNameWithLocale}>
                                PT
                              </S.LinkMenu>
                            </S.MenuItem>
                          ) : (
                            <S.MenuItem>
                              <S.LinkMenu hrefLang="en-us" to={pathNameWithLocale}>
                                EN
                              </S.LinkMenu>
                            </S.MenuItem>
                          )}
                        </S.LocaleSwitcher>
                      </S.Row>
                    </S.Col>
                  </Row>
                </S.Col>
              </S.Row>
            </S.InnerWrapper>
          </S.NavGrid>
        )
      }}
    </Location>
  )
}

MenuDesktop.propTypes = {
  locale: PropTypes.string.isRequired,
  isPt: PropTypes.bool.isRequired,
}
export default MenuDesktop
