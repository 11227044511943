import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import MenuMobile from './MenuMobile'
import MenuDesktop from './MenuDesktop'

const Header = ({ setIsOpen, isOpen, locale, isPt }) => (
  <Fragment>
    <MenuMobile setIsOpen={setIsOpen} isOpen={isOpen} locale={locale} isPt={isPt} />
    <MenuDesktop isPt={isPt} locale={locale} />
  </Fragment>
)

Header.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  isPt: PropTypes.bool.isRequired,
}
export default Header
