import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import moment from 'moment'

// eslint-disable-next-line import/no-cycle
import { LocaleContext } from '../Layout'

import { ExternalLink } from '../UI/Links'
import { Facebook, Instagram } from '../UI/Social'
import envelop from '../../../content/icons/envelop.svg'
import logo from '../../../content/images/fulllogo.svg'
import ticaBadge from '../../../content/images/tica-badge-blue.png'

import * as S from './styled'

const Footer = ({ isPt }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const {
    footer: {
      data: {
        email,
        badges,
        tica_outstanding_cattery: { url: certificateUrl },
      },
    },
  } = useStaticQuery(pageQuery)

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Row>
          <S.Col>
            <S.Label>{i18n.footer.colBengal.label}</S.Label>
            <S.LinkMenu to={isPt ? `/${i18n.link1}` : `/en/${i18n.link1}`}>
              {i18n.footer.colBengal.linkText1}
            </S.LinkMenu>
            <S.LinkMenu to={isPt ? `/${i18n.link2}` : `/en/${i18n.link2}`}>
              {i18n.footer.colBengal.linkText2}
            </S.LinkMenu>
            <S.LinkMenu to={isPt ? `/${i18n.footer.colBengal.link3}` : `/en/${i18n.footer.colBengal.link3}`}>
              {i18n.footer.colBengal.linkText3}
            </S.LinkMenu>
            <S.LinkMenu to={isPt ? `/${i18n.footer.colBengal.link4}` : `/en/${i18n.footer.colBengal.link4}`}>
              {i18n.footer.colBengal.linkText4}
            </S.LinkMenu>
          </S.Col>
          <S.Col>
            <S.Label>{i18n.footer.colExample.label}</S.Label>
            <S.LinkMenu to={isPt ? `/${i18n.droplink1}` : `/en/${i18n.droplink1}`}>
              {i18n.footer.colExample.linkText1}
            </S.LinkMenu>
            <S.LinkMenu to={isPt ? `/${i18n.droplink2}` : `/en/${i18n.droplink2}`}>
              {i18n.footer.colExample.linkText2}
            </S.LinkMenu>
            <S.LinkMenu to={isPt ? `/${i18n.droplink3}` : `/en/${i18n.droplink3}`}>
              {i18n.footer.colExample.linkText3}
            </S.LinkMenu>
          </S.Col>
          <S.Col>
            <S.Label>{i18n.footer.colLegal.label}</S.Label>
            <S.LinkMenu to={isPt ? `/${i18n.footer.colLegal.link2}` : `/en/${i18n.footer.colLegal.link2}`}>
              {i18n.footer.colLegal.linkText2}
            </S.LinkMenu>
          </S.Col>
          <S.MailCol>
            <S.MailTitle>{i18n.footer.colMail.title}</S.MailTitle>
            <S.MailText>{i18n.footer.colMail.text}</S.MailText>
            <S.MailText>{email}</S.MailText>
            <S.MailLink href={`mailto:${email}`}>
              <S.Button>
                <img src={envelop} alt="Contact" />
                {i18n.footer.button}
              </S.Button>
            </S.MailLink>
          </S.MailCol>
        </S.Row>
        <S.RowSocial>
          <S.SocialLabel>{i18n.footer.socialLabel}</S.SocialLabel>
          <S.InnerRowSocial>
            <ExternalLink target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/rui.r.fernandes.7">
              <Facebook style={{ marginRight: '16px', width: '29px' }} alt="Facebook Bengalesisnika" />
            </ExternalLink>
            <ExternalLink target="_blank" rel="noopener noreferrer" href="https://instagram.com/rui_bengalesisnika">
              <Instagram style={{ width: '29px' }} alt="Instagram Bengalesisnika" />
            </ExternalLink>
          </S.InnerRowSocial>
        </S.RowSocial>
        <S.BottomRow>
          <S.LogoCol>
            <Link to={isPt ? '/' : '/en'}>
              <S.Logo src={logo} alt="Bengalesisnika" />
            </Link>
          </S.LogoCol>
          <S.TicaCol>
            <ExternalLink target="_blank" rel="noopener noreferrer" href={certificateUrl}>
              Tica Outstanding Cattery
              <S.Badge src={ticaBadge} alt="Tica Outstanding Cattery" />
            </ExternalLink>
          </S.TicaCol>
          <S.BadgesCol>
            {badges.map(badge => {
              const {
                image: { url: src },
                name,
                link: { url },
              } = badge

              return (
                <ExternalLink key={name} target="_blank" rel="noopener noreferrer" href={url}>
                  <S.Badge src={src} alt={name} />
                </ExternalLink>
              )
            })}
          </S.BadgesCol>
        </S.BottomRow>
        <S.DateRow>Bengalesisnika - {moment().format('YYYY')} ®</S.DateRow>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

Footer.propTypes = {
  isPt: PropTypes.bool.isRequired,
}

export default Footer
export const pageQuery = graphql`
  query test {
    footer: prismicFooter {
      data {
        email
        tica_outstanding_cattery {
          url
        }
        badges {
          image {
            url
          }
          name
          link {
            url
          }
        }
      }
    }
  }
`
