// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-litter-jsx": () => import("/opt/build/repo/src/templates/litter.jsx" /* webpackChunkName: "component---src-templates-litter-jsx" */),
  "component---src-templates-category-jsx": () => import("/opt/build/repo/src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("/opt/build/repo/src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-bengal-jsx": () => import("/opt/build/repo/src/pages/bengal.jsx" /* webpackChunkName: "component---src-pages-bengal-jsx" */),
  "component---src-pages-contacts-jsx": () => import("/opt/build/repo/src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-error-jsx": () => import("/opt/build/repo/src/pages/error.jsx" /* webpackChunkName: "component---src-pages-error-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kings-jsx": () => import("/opt/build/repo/src/pages/kings.jsx" /* webpackChunkName: "component---src-pages-kings-jsx" */),
  "component---src-pages-litters-jsx": () => import("/opt/build/repo/src/pages/litters.jsx" /* webpackChunkName: "component---src-pages-litters-jsx" */),
  "component---src-pages-news-jsx": () => import("/opt/build/repo/src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-privacy-jsx": () => import("/opt/build/repo/src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-queens-jsx": () => import("/opt/build/repo/src/pages/queens.jsx" /* webpackChunkName: "component---src-pages-queens-jsx" */),
  "component---src-pages-reservation-jsx": () => import("/opt/build/repo/src/pages/reservation.jsx" /* webpackChunkName: "component---src-pages-reservation-jsx" */),
  "component---src-pages-success-jsx": () => import("/opt/build/repo/src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

