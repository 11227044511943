import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { theme, reset } from '../../styles'

export const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    width: 100%;
    color: ${theme.colors.black};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.orange};
  }
  a {
    color: ${theme.colors.black};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 2rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }
`

export const Children = styled.div`
  @keyframes position {
    from {
      position: static;
    }
    to {
      position: fixed;
      height: 100%;
      overflow: hidden;
    }
  }
  ${props =>
    props.isOpen &&
    css`
      animation: position 0.5s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
    `}
`
