const website = require('./website')

module.exports = {
  'pt-pt': {
    default: true,
    path: 'pt',
    locale: 'pt-pt',
    siteLanguage: 'pt',
    ogLang: 'pt_PT',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: 'Gatos de Bengal. De Portugal para o mundo!',
    headline: 'Bengalesisnika',
    category: 'Categoria',
    categories: 'Categorias',
    was: 'wurde',
    were: 'wurden',
    tagged: 'markiert mit',
    recent: 'Novas',
    projects: 'Projetos',
    posts: 'notícias',
    allCategories: 'Todas as categorias',
    entries: 'Entradas',
    link1: 'about-us',
    link2: 'bengal',
    linkText1: 'Quem Somos',
    linkText2: 'Raça',
    dropdown: 'Exemplares',
    droplink1: 'kings',
    droplink2: 'queens',
    droplink3: 'litters',
    droplinkText1: 'Reis',
    droplinkText2: 'Raínhas',
    droplinkText3: 'Príncipes',
    buttonPost: 'Saber mais',
    reservation: 'Reserva de gatinhos',
    effectiveDate: 'Data efectiva:',
    footer: {
      colBengal: {
        label: 'bengalesisnika',
        link1: 'about-us',
        link2: 'bengal',
        link3: 'contacts',
        link4: 'news',
        linkText1: 'Quem Somos',
        linkText2: 'Raça',
        linkText3: 'Contactos',
        linkText4: 'Notícias',
      },
      colExample: {
        label: 'exemplares',
        link1: 'kings',
        link2: 'queens',
        link3: 'litters',
        linkText1: 'Os reis',
        linkText2: 'As raínhas',
        linkText3: 'Os príncipes',
      },
      colLegal: {
        label: 'legal',
        link1: 'Termos & Condições',
        link2: 'privacy',
        linkText2: 'Política de Privacidade',
      },
      colMail: {
        title: 'Tem alguma dúvida?',
        text: 'Envie e-mail para',
      },
      button: 'Contactar',
      socialLabel: 'Siga-nos',
    },
    certificate: 'Certificado HCM',
    fiv: 'Certificado FIV/FELV',
    pedigree: 'Pedigree',
    dob: 'Data de nascimento',
    origin: 'Origem',
    mother: 'Mãe',
    father: 'Pai',
    seeLitter: 'Ver ninhada',
    reserve: 'Reservar',
    reserved: 'Reservado',
    underEvaluation: 'Em evolução',
    moreLitters: 'Ver mais ninhadas',
    moreNews: 'Ver mais notícias',
    openLink: 'Ver documento',
    address: 'Morada',
    contactUs: 'Ligue',
    noLitters: 'Atualmente não existem ninhadas disponíveis. Visite-nos novamente em breve para novidades!',
    formGeneral: {
      firstName: {
        label: 'Primeiro Nome',
        placeholder: 'O seu primeiro name',
      },
      lastName: {
        label: 'Último Nome',
        placeholder: 'O seu último nome',
      },
      email: {
        label: 'E-mail',
        placeholder: 'O seu e-mail',
      },
      contact: {
        label: 'O seu contacto',
        placeholder: 'O seu número de telefone',
      },
      message: {
        label: 'A sua mensagem',
        placeholder: 'Por favor deixe aqui a sua mensagem',
      },
      submit: 'Enviar mensagem',
      errors: {
        required: 'Campo obrigatório',
        emailInvalid: 'Formato de email inválido',
      },
    },
    successPage: {
      title: 'Obrigado pela sua mensagem!',
      description: 'Responderemos assim que possível.',
      button: 'Voltar à página inicial',
    },
    errorPage: {
      title: 'Ups! Ocorreu um erro!',
      description:
        'Por favor, tente de novo. Se continuar a obter este erro, contacte-nos diretamente pelo email info@bengalesisnika.pt',
      button: 'Voltar à página inicial',
    },
    notFound: {
      title: 'Ups! Ocorreu um erro!',
      description: 'A página que procura não se encontra disponível.',
      button: 'Voltar à página inicial',
    },
    formReservation: {
      firstName: {
        label: 'Primeiro Nome',
        placeholder: 'O seu primeiro name',
      },
      lastName: {
        label: 'Último Nome',
        placeholder: 'O seu último nome',
      },
      email: {
        label: 'E-mail',
        placeholder: 'O seu e-mail',
      },
      contact: {
        label: 'O seu contacto',
        placeholder: 'O seu número de telefone',
      },
      street: {
        label: 'Morada',
        placeholder: 'Rua',
      },
      doorNumber: {
        placeholder: 'Número da porta',
      },
      postalCode: {
        placeholder: 'Código posta',
      },
      city: {
        placeholder: 'A sua cidade',
      },
      occupation: {
        label: 'Profissão',
        placeholder: 'A sua profissão',
      },
      reference: {
        label: 'Como conheceu Bengalesisnika (por exemplo, navegação na Web, referência, propaganda)?',
        placeholder: 'A sua resposta',
      },
      kitten: {
        label: 'Indique qual dos gatinhos lhe interessou e a respetiva ninhada.',
        placeholder: 'A sua resposta',
      },
      interest: {
        label: 'Alguma vez teve um gato antes? O que é que o fez ter interesse num Bengal?',
        placeholder: 'A sua resposta',
      },
      house: {
        label: 'Por favor, indique o tipo de habitação onde vive neste momento.',
        placeholder: 'A sua resposta',
      },
      personResponsible: {
        label:
          'Quem será a principal responsável pelo gatinho / gato? Quantas horas você ou seus familiares terão que passar com o gatinho / gato durante o dia?',
        placeholder: 'A sua resposta',
      },
      otherAnimals: {
        label: 'Há algum outro animal de estimação em sua casa neste momento? Se sim, que espécies e quantas?',
        placeholder: 'A sua resposta',
      },
      veterinary: {
        label:
          'Por favor, forneça o nome e os detalhes de contato do veterinário que você usará para o seu gato / gatinho.',
        placeholder: 'A sua resposta',
      },
      submit: 'Enviar pedido de reserva',
      errors: {
        required: 'Campo obrigatório',
        emailInvalid: 'Formato de email inválido',
      },
    },
    seo: {
      homepage: {
        title: 'O seu gatil de referência',
      },
      aboutUs: {
        title: 'Quem é Bengalesisnika?',
      },
      bengal: {
        title: 'Saiba mais sobre bengals',
      },
      contactUs: {
        title: 'Contacte-nos',
      },
      kings: {
        title: 'Os nossos reis',
      },
      queens: {
        title: 'As nossas raínhas',
      },
      litters: {
        title: 'Os nossos príncipes',
      },
      news: {
        title: 'Notícas Bengalesisnika',
      },
      reservation: {
        title: 'Reserve hoje',
      },
      error: {
        title: 'Ups! Ocorreu um erro.',
      },
      privacy: {
        title: 'Política de privacidade',
      },
    },
  },
  'en-us': {
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: 'Category',
    categories: 'Categories',
    was: 'was',
    were: 'were',
    tagged: 'tagged with',
    recent: 'Recent',
    projects: 'projects',
    posts: 'news',
    allCategories: 'All categories',
    entries: 'entries',
    link1: 'about-us',
    link2: 'bengal',
    linkText1: 'About us',
    linkText2: 'Bengal',
    dropdown: 'Our breed',
    droplink1: 'kings',
    droplink2: 'queens',
    droplink3: 'litters',
    droplinkText1: 'Kings',
    droplinkText2: 'Queens',
    droplinkText3: 'Princes',
    buttonPost: 'Know more',
    reservation: 'Kittens Reservation',
    effectiveDate: 'Effective date:',
    footer: {
      colBengal: {
        label: 'bengalesisnika',
        link1: 'about-us',
        link2: 'bengal',
        link3: 'contacts',
        link4: 'news',
        linkText1: 'About us',
        linkText2: 'Bengal',
        linkText3: 'Contacts',
        linkText4: 'News',
      },
      colExample: {
        label: 'Our breed',
        link1: 'kings',
        link2: 'queens',
        link3: 'litters',
        linkText1: 'Kings',
        linkText2: 'Queens',
        linkText3: 'Princes',
      },
      colLegal: {
        label: 'legal',
        link1: 'Terms & Conditions',
        link2: 'privacy',
        linkText2: 'Privacy Policy',
      },
      colMail: {
        title: 'Have questions?',
        text: 'Send e-mail to',
      },
      button: 'Contact',
      socialLabel: 'Follow us',
    },
    certificate: 'HCM Certificate',
    fiv: 'FIV/FELV Certificate',
    pedigree: 'Pedigree',
    dob: 'Date of birth',
    origin: 'Origin',
    mother: 'Mother',
    father: 'Father',
    seeLitter: 'See litter',
    reserve: 'Reserve',
    reserved: 'Reserved',
    underEvaluation: 'Under evaluation',
    moreLitters: 'See more litters',
    moreNews: 'See more news',
    openLink: 'Open document',
    address: 'Address',
    contactUs: 'Contact Us',
    noLitters: 'There are no litters available at the moment. Please visit us again soon for more news!',
    formGeneral: {
      firstName: {
        label: 'First Name',
        placeholder: 'Your first name',
      },
      lastName: {
        label: 'Last Name',
        placeholder: 'Your last name',
      },
      email: {
        label: 'E-mail',
        placeholder: 'Your e-mail',
      },
      contact: {
        label: 'Your contact',
        placeholder: 'Your telephone number',
      },
      message: {
        label: 'Your message',
        placeholder: 'Please write here you questions',
      },
      submit: 'Send message',
      errors: {
        required: 'Required field',
        emailInvalid: 'Invalid e-mail address',
      },
    },
    successPage: {
      title: 'Thank you for your submission!',
      description: "We'll get back to you as soon as possible.",
      button: 'Go to homepage',
    },
    errorPage: {
      title: 'Ups! An error as occured!',
      description:
        'Please try again. If you keep getting this, please contact directly by our email info@bengalesisnika.pt',
      button: 'Go to homepage',
    },
    notFound: {
      title: 'Ups! An error as occured!',
      description: 'The page that you are looking does not exist.',
      button: 'Go to homepage',
    },
    formReservation: {
      firstName: {
        label: 'First Name',
        placeholder: 'Your first name',
      },
      lastName: {
        label: 'Last Name',
        placeholder: 'Your last name',
      },
      email: {
        label: 'E-mail',
        placeholder: 'Your e-mail',
      },
      contact: {
        label: 'Your contact',
        placeholder: 'Your telephone number',
      },
      street: {
        label: 'Address',
        placeholder: 'Street',
      },
      doorNumber: {
        placeholder: 'Door number',
      },
      postalCode: {
        placeholder: 'Postal code',
      },
      city: {
        placeholder: 'City',
      },
      occupation: {
        label: 'Occupation',
        placeholder: 'Your occupation',
      },
      reference: {
        label: 'How did you get to know Bengalesisnika (eg web browsing, reference, advertising)?',
        placeholder: 'Your answer',
      },
      kitten: {
        label: 'Indicate which kitten interested you and the respective litter.',
        placeholder: 'Your answer',
      },
      interest: {
        label: 'Have you ever had a cat before? What makes you interested in a Bengal?',
        placeholder: 'Your answer',
      },
      house: {
        label: 'Please indicate the type of house where you live at the moment.',
        placeholder: 'Your answer',
      },
      personResponsible: {
        label:
          'Who will be primarily responsible for the kitten / cat? How many hours will you or your family members have to spend with the kitten / cat during the day?',
        placeholder: 'Your answer',
      },
      otherAnimals: {
        label: 'Are there any other pets in your house at this time? If yes, what species and how many?',
        placeholder: 'Your answer',
      },
      veterinary: {
        label: `Please provide the veterinarian's name and contact details that you will use for your cat / kitten.`,
        placeholder: 'Your answer',
      },
      submit: 'Send reservation reques',
      errors: {
        required: 'Required field',
        emailInvalid: 'Invalid e-mail address',
      },
    },
    seo: {
      homepage: {
        title: `Your catterie's reference`,
      },
      aboutUs: {
        title: 'Who is Bengalesisnika?',
      },
      bengal: {
        title: 'Know more about bengals',
      },
      contactUs: {
        title: 'Contact us',
      },
      kings: {
        title: 'Our kings',
      },
      queens: {
        title: 'Our queens',
      },
      litters: {
        title: 'Our princes',
      },
      news: {
        title: 'News from Bengalesisnika',
      },
      reservation: {
        title: 'Reserve today',
      },
      error: {
        title: 'Ups! An error as occured',
      },
      privacy: {
        title: 'Our privacy policy',
      },
    },
  },
}
