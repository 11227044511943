import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { Row as DefaultRow, Col as DefaultCol } from '../UI/Grid'
import { ButtonWhite } from '../UI/Buttons'
import { ExternalLink } from '../UI/Links'

export const Wrapper = styled.footer`
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: ${props => props.theme.maxWidth};
  margin: 6rem auto 0 auto;
  padding: 16px;
  color: ${props => props.theme.colors.grey};
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 0;
  }
`

export const InnerWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
`

export const Row = styled(DefaultRow)`
  justify-content: center;
  padding: 0;
`
export const RowSocial = styled(Row)`
  padding: 0 0 48px 0;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-top: 32px;
    justify-content: center;
  }
`

export const InnerRowSocial = styled(DefaultRow)`
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-top: 16px;
    flex-direction: row;
    justify-content: center;
  }
`

export const BottomRow = styled(Row)`
  border-top: 1px solid ${({ theme }) => theme.colors.greyLighter};
  padding: 0;
  justify-content: space-between;
`
export const Label = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
`

export const SocialLabel = styled(Label)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: block;
    text-align: center;
  }
`
export const Col = styled(DefaultCol)`
  width: calc(100% / 4);

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    width: calc(100% / 2);

    margin-top: 32px;
  }
`

export const MailCol = styled(Col)`
  background-color: ${({ theme }) => theme.colors.orange};
  border-radius: 4px;
  padding: 26px;
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    width: 100%;
  }
`

export const TicaCol = styled(Col)`
  align-self: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-bottom: 32px;
  }

  ${ExternalLink} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const BadgesCol = styled(DefaultCol)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    justify-content: center;
    padding-bottom: 32px;
  }

  ${ExternalLink} {
    padding-right: 16px;

    :last-of-type {
      padding-right: 0;
    }
  }
`

export const LogoCol = styled(DefaultCol)`
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    text-align: center;
    padding-bottom: 32px;
  }
  span {
    font-size: 0.6rem;
    font-weight: bold;
  }
`
export const LinkMenu = styled(Link)`
  display: block;
  font-size: 0.8rem;
  margin: 16px 0;
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.greyLight};
  :hover {
    color: ${({ theme }) => theme.colors.orange};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-right: 0;
  }
`

export const Button = styled(ButtonWhite)`
  padding: 8px 26px;

  img {
    margin-right: 8px;
    width: 20px;
  }
  :hover {
    img {
      filter: brightness(0) invert(1);
    }
  }
`
export const MailText = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.bg};
  :last-of-type {
    padding-bottom: 16px;
  }
`
export const MailTitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.bg};
  padding-bottom: 16px;
`
export const MailLink = styled.a`
  text-decoration: none;
  font-size: 0.875rem;
`

export const Logo = styled.img`
  padding-top: 32px;
  max-width: 163px;
`

export const Badge = styled.img`
  max-height: 48px;
  max-width: 72px;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    width: auto;
    max-width: 100%;
  }
`

export const DateRow = styled(DefaultRow)`
  font-size: 0.6rem;
  font-weight: bold;
  justify-content: flex-end;
  top: -20px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    top: 0;
    text-align: center;
    justify-content: center;
  }
`
