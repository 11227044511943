import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Link } from 'gatsby'

import { Row as DefaultRow, Col as DefaultCol } from '../UI/Grid'
import { theme } from '../../styles'

export const Logo = styled(Link)`
  color: black;
  font-size: 30px;

  img {
    max-width: 240px;
  }
`

export const Col = styled(DefaultCol)`
  align-self: center;
`

export const Row = styled(DefaultRow)`
  justify-content: space-between;
  align-items: center;
`

export const LinkMenu = styled(Link)`
  font-size: 0.9rem;
  margin-right: 32px;
  font-weight: bold;
  :hover {
    color: ${theme.colors.orange};
  }
  &.NavLink__currentPath {
    color: ${theme.colors.orange};
  }

  @media (max-width: ${theme.breakpoints.m}) {
    margin-right: 0;
  }
`

export const LocaleSwitcher = styled.div`
  display: flex;
  align-items: center;
  ${LinkMenu} {
    margin: 0;
  }
`
export const NavGrid = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 36px 16px;
  z-index: 200;
  position: relative;

  @media (max-width: ${theme.breakpoints.m}) {
    display: none;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
`

export const Container = styled.div`
  display: none;

  @media (max-width: ${theme.breakpoints.m}) {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
    position: relative;

    ${Logo} {
      font-size: 1.5rem;
    }
  }
`

export const Content = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  margin-bottom: 65px;
`

export const MenuMobile = styled.ul`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: -39px;
  top: -24px;
  padding: 125px 16px 50px 16px;
  background-color: #f5f6fa;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  ${props =>
    props.isOpen &&
    css`
      transform: none;
    `}
`

export const MenuItem = styled.li`
  padding: 10px 0;
  transition-delay: 2s;
  list-style-type: none;
`

export const NavMobile = styled.div`
  height: 65px;
  position: fixed;
  background: white;
  width: 100%;
  margin-bottom: 65px;

  box-shadow: 0px 1px 10px #999;
`

export const MenuToogle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  left: 16px;
  z-index: 120;
  user-select: none;
`

export const Button = styled.button`
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
`

export const Line = styled.span`
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #000;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;

  :nth-of-type(2) {
    transform-origin: 0% 100%;
  }

  ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      transform: rotate(45deg) translate(-3px, -1px);
      background: #36383f;

      :nth-of-type(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      :nth-of-type(2) {
        transform: rotate(-45deg) translate(-4px, 4px);
      }
    `}
`

export const Dropdown = styled.span`
  position: absolute;
  z-index: 130;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 140px;
  margin-top: 20px;
  opacity: 0;
  pointer-events: none;
  background-color: ${theme.colors.bg};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: opacity 0.2s ease, top 0.2s ease;
  ${LinkMenu} {
    padding: 16px 16px 0;
    text-decoration: none;
    display: block;
    :last-of-type {
      padding: 16px;
    }
  }
`

export const DropdownLink = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${theme.colors.black};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${theme.colors.orange};
  }
`

export const ListItem = styled(MenuItem)`
  margin-right: 32px;
  position: relative;

  &:hover ${Dropdown} {
    top: 24px;
    pointer-events: auto;
    opacity: 1;
  }

  ${LinkMenu} {
    margin-right: 0;
  }
`

export const Square = styled.div`
  position: absolute;
  top: -4px;
  left: 50%;
  display: block;
  height: 12px;
  width: 12px;
  background: white;
  box-shadow: -4px -3px 24px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  transform: translateX(-50%) rotate(45deg);
`
