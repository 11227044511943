import styled from '@emotion/styled'

export const Button = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 100;
  background: ${({ theme }) => theme.colors.orange};
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.bg};
  transition: background 0.2s ease-in-out;
  :hover {
    background: ${({ theme }) => theme.colors.black};
  }
  :focus {
    outline: 0;
  }
`

export const ButtonWhite = styled(Button)`
  color: ${({ theme }) => theme.colors.orange};
  background: ${({ theme }) => theme.colors.bg};
  :hover {
    color: ${({ theme }) => theme.colors.bg};
  }
`
