import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Wrapper = styled.div`
  width: 100%;
`
export const InnerWrapper = styled.div`
  max-width: ${props => (props.fullWidth ? '100%' : '1200px')};
  height: 100%;
  min-height: calc(100vh - 247px);
  margin: 0 auto;
  background-color: ${props => (props.color ? props.color : 'white')};
`
export const TopWrapper = styled.div`
  position: relative;
  z-index: 2;
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  width: 100%;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`

export const GreenWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 328px;
  background-color: ${({ theme }) => theme.colors.green.greenAlpha};
  transform: translateY(-50%);

  @media (max-width: 600px) {
    top: calc(50% - 24px);
    height: 402px;
  }
`
export const CongratulationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 247px);
  text-align: center;
`

export const Row = styled.div`
  display: flex;

  width: 100%;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '1200px')};

  padding: 0;
  margin: 0 auto;

  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  ${props =>
    props.noMobile &&
    css`
      @media (max-width: 600px) {
        display: none;
      }
    `};
  
  ${props =>
    props.mobileRow &&
    css`
      @media (max-width: 600px) {
        flex-direction: row;
      }
    `};

  ${p =>
    p.rightDesktop &&
    css`
      @media (min-width: 601px) {
        justify-content: flex-end;
      }
    `}
  
  ${p =>
    p.spaceBetweenMobile &&
    css`
      @media (max-width: 600px) {
        justify-content: space-between;
      }
    `}
  
  ${p =>
    p.noWrap &&
    css`
      flex-wrap: nowrap;
    `}

  ${p =>
    p.fullHeight &&
    css`
      height: 100vh;
    `}
`
export const TitleRow = styled(Row)`
  padding: 64px 0 32px 0;
  align-items: center;
`

export const Col = styled.div`
  position: relative;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}%` : '100%')};

  order: -1;
  z-index: 1;

  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }

  ${p =>
    p.centerMobile &&
    css`
      @media (max-width: 600px) {
        text-align: center;
      }
    `};
  ${p =>
    p.maxWidth &&
    css`
      max-width: ${p.maxWidth};
    `};
  ${p =>
    p.flexMobile &&
    css`
      @media (max-width: 600px) {
        flex: ${p.flexMobile};
      }
    `};
`

export const FixedCol = styled.div`
  ${p =>
    p.span && p.span > 0 && p.span < 12
      ? css`
          flex-basis: calc((${p.span} * 100 / 12) * 1%);
        `
      : css`
          flex-basis: 100%;
        `} padding: 0 12px 24px;

  order: -1;

  @media (max-width: 768px) {
    padding: 0 16px 24px;
  }

  @media (max-width: 600px) {
    padding: 0 8px 16px;
    margin-bottom: 24px;

    flex-basis: 100%;

    order: 1;

    :nth-last-of-type {
      margin-bottom: 0px;
    }
  }
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grayscale.grey.light};
  width: 100%;
`

export const ColFooter = styled(Col)`
  @media (max-width: 600px) {
    padding: 4px 0;
    text-align: left;
  }
  @media (max-width: 404px) {
    min-width: 100%;
  }
  ${p =>
    p.alignRightMobile &&
    css`
      @media (min-width: 405px) and (max-width: 600px) {
        text-align: right;
      }
    `};
`
