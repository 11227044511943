import React from 'react'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'

import { LocaleContext } from '../Layout'
import logo from '../../../content/images/logo-simple.svg'
import * as S from './styled'

const MenuMobile = ({ setIsOpen, isOpen, locale, isPt }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <Location>
      {({ location }) => {
        const pathNameWithLocale = isPt ? `/en${location.pathname}` : location.pathname.replace('/en', '')

        return (
          <S.Container>
            <S.Content>
              <S.NavMobile isOpen={isOpen} role="navigation">
                <S.MenuToogle>
                  <S.Button onClick={() => setIsOpen(!isOpen)} />
                  <S.Line isOpen={isOpen} />
                  <S.Line isOpen={isOpen} />
                  <S.Line isOpen={isOpen} />
                  <S.MenuMobile isOpen={isOpen}>
                    <S.MenuItem>
                      <S.Logo onClick={() => setIsOpen(!isOpen)} to={isPt ? '/' : '/en'}>
                        <img src={logo} alt="Bengalesisnika" />
                      </S.Logo>
                    </S.MenuItem>
                    <S.MenuItem>
                      <S.LinkMenu
                        activeClassName="NavLink__currentPath"
                        onClick={() => setIsOpen(!isOpen)}
                        to={isPt ? `/${i18n.link1}` : `/en/${i18n.link1}`}
                      >
                        {i18n.linkText1}
                      </S.LinkMenu>
                    </S.MenuItem>
                    <S.MenuItem>
                      <S.LinkMenu
                        activeClassName="NavLink__currentPath"
                        onClick={() => setIsOpen(!isOpen)}
                        to={isPt ? `/${i18n.link2}` : `/en/${i18n.link2}`}
                      >
                        {i18n.linkText2}
                      </S.LinkMenu>
                    </S.MenuItem>
                    <S.MenuItem>
                      <S.LinkMenu
                        activeClassName="NavLink__currentPath"
                        onClick={() => setIsOpen(!isOpen)}
                        to={isPt ? `/${i18n.droplink1}` : `/en/${i18n.droplink1}`}
                      >
                        {i18n.droplinkText1}
                      </S.LinkMenu>
                    </S.MenuItem>
                    <S.MenuItem>
                      <S.LinkMenu
                        activeClassName="NavLink__currentPath"
                        onClick={() => setIsOpen(!isOpen)}
                        to={isPt ? `/${i18n.droplink2}` : `/en/${i18n.droplink2}`}
                      >
                        {i18n.droplinkText2}
                      </S.LinkMenu>
                    </S.MenuItem>
                    <S.MenuItem>
                      <S.LinkMenu
                        activeClassName="NavLink__currentPath"
                        onClick={() => setIsOpen(!isOpen)}
                        to={isPt ? `/${i18n.droplink3}` : `/en/${i18n.droplink3}`}
                      >
                        {i18n.droplinkText3}
                      </S.LinkMenu>
                    </S.MenuItem>
                    <S.LocaleSwitcher data-name="locale-switcher">
                      {locale !== 'pt-pt' ? (
                        <S.MenuItem>
                          <S.LinkMenu onClick={() => setIsOpen(!isOpen)} hrefLang="pt-pt" to={pathNameWithLocale}>
                            PT
                          </S.LinkMenu>
                        </S.MenuItem>
                      ) : (
                        <S.MenuItem>
                          <S.LinkMenu onClick={() => setIsOpen(!isOpen)} hrefLang="en-us" to={pathNameWithLocale}>
                            EN
                          </S.LinkMenu>
                        </S.MenuItem>
                      )}
                    </S.LocaleSwitcher>
                  </S.MenuMobile>
                </S.MenuToogle>
              </S.NavMobile>
            </S.Content>
          </S.Container>
        )
      }}
    </Location>
  )
}

MenuMobile.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  isPt: PropTypes.bool.isRequired,
}
export default MenuMobile
