module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Bengalesisnika', // Navigation and Site Title
  titleAlt: 'Bengalesisnika', // Title for JSONLD
  description: 'Bengal Cats. From Portugal to the world!',
  headline: 'Bengal Cats Breeder', // Headline for schema.org JSONLD
  url: 'https://www.bengalesisnika.pt', // Domain of your site. No trailing slash!
  logo: '/logos/logo-1024.png', // Used for SEO
  ogLanguage: 'pt_PT', // Facebook Language
  defaultBanner: '/logos/logo-1024.png',

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Prismic', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Bengalesisnika', // Author for schemaORGJSONLD
  themeColor: '#F5A623',
  backgroundColor: '#FFFFFF',

  twitter: '@bengalesisnika', // Twitter Username
  facebook: 'rui.r.fernandes.7', // Facebook Site Name

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
