import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'

// External Link
export const ExternalLink = styled.a`
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    opacity: 0.6;
    color: ${({ theme }) => theme.colors.orange};
  }
`

export const Link = ({ external, to, children, ...props }) => (
  <Link {...props}>
    {external ? <ExternalLink href={to}>{children}</ExternalLink> : <GatsbyLink to={to}>{children}</GatsbyLink>}
  </Link>
)

// Mail Link
export const MailLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.bg};

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`
Link.propTypes = {
  external: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

Link.defaultProps = {
  external: false,
}
