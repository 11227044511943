/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

import { theme } from '../../styles'
import Footer from '../Footer'
import Header from '../Header'
import i18n from '../../../config/i18n'

import * as S from './styled'

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale } }) => {
  const [isOpen, setIsOpen] = useState(false)

  const isPt = locale === 'pt-pt'

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <ThemeProvider theme={theme}>
        <>
          <Global styles={S.globalStyle} />
          <Header setIsOpen={setIsOpen} isOpen={isOpen} locale={locale} isPt={isPt} />
          <S.Children isOpen={isOpen}>{children}</S.Children>
          <Footer isPt={isPt} />
        </>
      </ThemeProvider>
    </LocaleContext.Provider>
  )
}

export { LocaleContext, Layout }

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
